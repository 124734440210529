import tw, { css, styled } from 'twin.macro'
import { Link } from 'gatsby'
import { CustomCheckboxContainer } from '@reach/checkbox'

export const FullSection = tw.section`w-full`
export const HeroSection = styled.section`
	@media(min-width: 525px) {
		${tw`mb-12`}
	}
	${tw`flex flex-col w-full items-center bg-center bg-no-repeat bg-cover relative overflow-hidden mb-8 md:mb-16`}
	background-image: url(${({ sources }) => sources[0].base64});
	video {
		transform: scale(1);
	}

	@media (min-width: 375px) {
		height: 230px;
		max-height: 230px;
		video {
			transform: scale(1.2);
		}
	}

	@media (min-width: 525px) {
		height: 320px;
		max-height: 320px;
		video {
			transform: scale(1.2);
		}
	}

	@media (min-width: 768px) {
		height: 440px;
		max-height: 440px;
	}

	@media (min-width: 1024px) {
		height: 580px;
		max-height: 580px;
	}
	@media (min-width: 1280px) {
		height: 750px;
		max-height: 750px;
		video {
			transform: scale(1.1);
		}
	}
`
export const HeroItemsContainer = styled.div`
	@media(min-width: 525px) {
		${tw`pt-8`}
	}
	${tw`absolute flex flex-col w-full m-auto items-center justify-center h-full pb-4 pt-4 lg:p-0`}
	top:0;
	left: 0;
	right: 0;

	> svg {
		@media(min-width: 525px) {
			${tw`w-8/12`}
		}
		${tw`w-7/12 md:w-auto`}
	}

	> svg#mineral-swim-logo {
		${tw`lg:pb-8`}
		filter: drop-shadow(1px 4px 4px rgba(0,0,0,0.625));
	}

	.play,
	.pause {
		${tw`absolute cursor-pointer`}
		right: 10%;
		bottom: 10%;
	}

	@media (max-width: 525px) and (min-width: 325px) {
		#dolphin {
			width: 120px;
			height: 70px;
		}
		.play,
		.pause {
			height: 30px;
			width: 30px;
			right: 3%;
			bottom: 8%;
		}
	}

	@media (max-width: 767px) and (min-width: 525px) {
		#dolphin {
			width: 150px;
			height: 80px;
		}
		.play,
		.pause {
			height: 35px;
			width: 35px;
			right: 3%;
			bottom: 12%;
		}
	}

	@media (max-width: 1023px) and (min-width: 768px) {
		#dolphin {
			width: 250px;
			height: 150px;
		}
		.play,
		.pause {
			height: 50px;
			width: 50px;
			right: 5%;
			bottom: 8%;
		}
	}

	@media (max-width: 1279px) and (min-width: 1024px) {
		#dolphin {
			width: 300px;
			height: 200px;
		}
		.play,
		.pause {
			height: 50px;
			width: 50px;
			right: 5%;
			bottom: 3%;
		}
	}

	@media screen and (min-width: 1280px) {
		#dolphin {
			width: 400px;
			height: 250px;
		}
		.play,
		.pause {
			height: 70px;
			width: 70px;
			right: 10%;
			bottom: 10%;
		}
	}

	${tw`bg-cover bg-no-repeat bg-center`}
	@media (min-width: 325px) {
		${tw`bg-bottom`}
		background-image: url(${({ sources }) => (sources ? sources[1].srcWebp : '')});
		background-image: url(${({ sources }) => (sources ? sources[1].src : '')});
	}
	@media (min-width: 525px) {
		${tw`bg-bottom`}
		background-image: url(${({ sources }) => (sources ? sources[0].srcWebp : '')});
		background-image: url(${({ sources }) => (sources ? sources[0].src : '')});
	}
`
export const HeroButtons = styled.div`
	@media (min-width: 525px) {
		${tw`flex-row mt-4`}
	}
	${tw`flex flex-col lg:mt-8`}
`
export const HeroSeeAllLink = styled(Link)`
	@media (min-width: 525px) {
		${tw`mb-0 mr-2`}
	}
	${tw`border border-yellow-500 bg-yellow-500 hover:border-yellow-400 hover:bg-yellow-400 rounded mb-1 mr-0`}
`
export const HeroSeeAll = styled.button`
	@media (min-width: 525px) {
		${tw`text-sm`}
	}
	${tw`font-display text-white uppercase text-white text-xs md:text-base py-2 lg:py-4 px-8 leading-none w-full`}
`
export const HeroBookLink = styled(Link)`
	@media (min-width: 525px) {
		${tw`ml-2`}
	}
	${tw`border border-mineral-teal rounded bg-mineral-teal hover:bg-blue-600 hover:border-blue-600 ml-0`}
`
export const HeroBook = styled.button`
	@media (min-width: 525px) {
		${tw`text-sm`}
	}
	${tw`font-display text-white leading-none text-xs md:text-base uppercase py-2 lg:py-4 px-8 `}
`
export const Bold = tw.b`font-bold`
export const Heading1 = styled.h1`
	@media (min-width: 525px) {
		${tw`text-2xl px-2`}
	}
	text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
	${tw`font-display text-white font-bold text-base md:px-0 md:text-3xl lg:text-4xl lg:text-5xl text-center my-2 md:my-4 md:w-10/12 xxxl:w-8/12 tracking-widest`}
`
export const BannerText = styled.p`
	@media (min-width: 525px) {
		${tw`block text-lg`}
	}
	text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
	${tw`font-display text-white hidden md:text-xl lg:text-2xl xl:text-3xl`}
`
export const DolphinLogo = styled.img`
	@media (min-width: 525px) {
		${tw`w-36 mt-0 mb-4 mr-0`}
	}
	${tw`w-32 md:w-48 lg:w-56 xl:w-1/6 mr-8`}
`
export const WaveContainer = tw.div`w-24 md:w-28 lg:w-32 xxl:w-full flex items-center justify-center`
export const HeroLogos = styled.div`
	@media (min-width: 525px) {
		${tw`flex-col`}
	}
	${tw`flex flex-row w-full items-center justify-center`}
`

export const Section = tw.section`w-full flex flex-col px-4 md:px-10`
export const Heading2 = styled.h2`
	@media (min-width: 525px) {
		${tw`text-2xl`}
	}
	${tw`font-display text-xl md:text-3xl text-mineral-teal text-center font-bold`}
`
export const Text = styled.p`
	@media (min-width: 525px) {
		${tw`text-xl mt-8 mb-8`}
	}
	${tw`font-display text-gray-500 w-full md:w-10/12 lg:w-9/12 mx-auto text-lg text-center px-0 lg:px-12 mt-4 mb-4 md:mb-16`}
`

export const MainSection = styled.section`
	${tw`pt-12 w-full`}
	background-color: #e5e5e5;
`
export const ProductsContainer = tw.div`flex flex-col md:flex-row md:px-4 w-full xl:w-4/5 xl:w-11/12 xxxl:w-3/4 m-auto items-center justify-center`
export const Main = tw.div`w-full`
export const ProductsInnerContainer = styled.div`
	${tw`flex flex-col justify-center`}
	${({ wrap }) => (wrap ? tw`flex-nowrap md:flex-wrap` : tw`flex-nowrap`)};
`
export const TrialRequest = styled(Link)`
	${tw`w-10/12 lg:w-8/12 xl:w-6/12 mx-auto mt-4 mb-2`}
`
export const Request = tw.button`font-display w-full uppercase border-2 border-yellow-500 hover:border-yellow-400 bg-yellow-500 hover:bg-yellow-400 hover:text-dolphin-gray text-white py-2 px-6 rounded cursor-pointer`
export const ItemDetails = styled.div`
	${tw`w-10/12 lg:w-8/12 xl:w-6/12 mx-auto`}
`
export const Continue = tw.button`w-full border-2 border-sky hover:border-sky bg-transparent hover:bg-sky hover:text-white text-sky py-2 px-6 rounded uppercase font-display`
export const NullText = tw.p`font-display text-dolphin-gray text-2xl bg-white p-8 mb-12 w-full text-center`
export const MainItemContainer = tw.div`flex flex-col md:flex-row mb-0 md:mb-12 justify-center`
export const ItemContainer = styled.div`
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	${tw`w-11/12 md:w-full mx-auto mb-4 md:mb-0 md:mx-2`}
`
export const ItemInnerContainer = tw.div`bg-white flex flex-col py-4 h-full md:flex-row justify-between w-11/12 md:w-full mx-auto`
export const ItemImageContainer = styled.div`
	max-height: 400px;
	${tw`flex items-center justify-center h-screen md:w-6/12`}
	&:hover {
		img {
			opacity: 0.875;
		}
	}
	@media (min-width: 325px) {
		max-height: 180px;
	}

	@media (min-width: 525px) {
		max-height: 300px;
	}
	@media (min-width: 768px) {
		max-height: 220px;
	}
	@media (min-width: 1024px) {
		max-height: 300px;
	}
	@media (min-width: 1280px) {
		max-height: 350px;
	}
	@media (min-width: 1400px) {
		max-height: 400px;
	}

	img {
		@media (min-width: 325px) {
			max-height: 180px;
		}
		@media (min-width: 525px) {
			max-height: 300px;
		}
		@media (min-width: 768px) {
			max-height: 220px;
		}
		@media (min-width: 1024px) {
			max-height: 300px;
		}
		@media (min-width: 1280px) {
			max-height: 350px;
		}
		@media (min-width: 1400px) {
			max-height: 400px;
		}
	}
`
export const ItemImage = styled.picture`
	${tw`h-full`}
`
export const DetailsContainer = tw.div`w-10/12 xl:w-8/12 xxxl:w-10/12 mx-auto`
export const ProductName = styled.h3`
	span {
		${tw`font-bold`}
	}
	${tw`font-display font-bold text-center text-xl mt-4 mb-2`}
`
export const MainProductName = styled(ProductName)`
	${tw`md:text-3xl mt-0 mb-4`}
`
export const Feature = tw.p`font-display text-gray-600 text-center text-sm`
export const MainFeature = styled(Feature)`
	${tw`md:text-base`}
`
export const SubItemContainer = styled(MainItemContainer)`
	${tw`flex-wrap md:mb-4 justify-start`}
`
export const SubItemsContainer = styled.div`
	${({ wid }) => (wid ? tw`w-11/12 md:w-6/12` : tw`w-11/12 md:w-4/12`)};
	${tw`px-0 md:px-2 mb-4 mx-auto md:mx-0`}
`
export const SubItemsInnerContainer = styled.div`
	${({ fullHeight }) => (fullHeight ? tw`h-full` : tw`h-auto`)};
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	${tw`flex flex-col`}
`
export const SubItemsDetails = styled.div`
	${({ fullHeight }) => (fullHeight ? tw`h-full` : tw`h-auto`)};
	${tw`bg-white py-4 flex flex-col`}
`
export const SubItemBtns = styled.div`
	${({ marginAuto }) => (marginAuto ? tw`m-auto` : tw`mt-4`)};
	${tw`w-full`}
`
export const SubItemImageContainer = styled.div`
	max-height: 300px;
	${tw`flex items-center justify-center h-screen`}
	&:hover {
		img {
			opacity: 0.875;
		}
	}
	@media (min-width: 325px) {
		max-height: 180px;
	}

	@media (min-width: 525px) {
		max-height: 300px;
	}

	@media (min-width: 768px) {
		max-height: 150px;
	}

	@media (min-width: 1024px) {
		max-height: 200px;
	}
	@media (min-width: 1280px) {
		max-height: 250px;
	}
	@media (min-width: 1400px) {
		max-height: 300px;
	}
	img {
		@media (min-width: 325px) {
			max-height: 180px;
		}

		@media (min-width: 525px) {
			max-height: 300px;
		}

		@media (min-width: 768px) {
			max-height: 150px;
		}

		@media (min-width: 1024px) {
			max-height: 200px;
		}
		@media (min-width: 1280px) {
			max-height: 250px;
		}
		@media (min-width: 1400px) {
			max-height: 300px;
		}
	}
`
export const SubItemDetails = styled(ItemDetails)`
	${tw`block w-10/12 md:w-full md:px-2 lg:px-0 lg:w-10/12 xl:w-8/12 xxl:w-7/12`}
`
export const SubTrialRequest = styled(Link)`
	${tw`mx-auto block w-10/12 md:w-full md:px-2 lg:px-0 lg:w-10/12 xl:w-8/12 xxl:w-7/12 mb-2 mt-4`}
`
export const Compare = tw.a`py-3 xxl:py-4 px-4 bg-yellow-500 hover:bg-yellow-400 text-white hover:text-sky font-display uppercase rounded-lg text-center mb-12 text-base md:text-lg tracking-wider mx-auto`

export const TwoBannerContainer = tw.div`flex flex-col md:flex-row`
export const PowerHouseContainer = styled.div`
	@media (min-width: 325px) {
		height: 300px;
		min-height: 300px;
	}
	@media (min-width: 525px) {
		height: 400px;
		min-height: 400px;
	}
	height: 500px;
	min-height: 500px;
	${tw`flex flex-col items-center justify-center px-8 lg:px-12 py-12 md:py-20 w-full md:w-6/12 bg-yellow-500`}
`
export const WaveLogoContainer = styled.div`
	${tw`mb-8`}

	> svg {
		${tw`w-36 lg:w-48 xxl:w-auto`}
	}
`
export const TwoBannerHeading2 = tw.h2`font-display text-white font-bold text-4xl lg:text-5xl xxl:text-6xl uppercase text-center tracking-wider`

export const WhichModelContainer = styled.div`
	@media (min-width: 325px) {
		height: 300px;
		min-height: 300px;
	}
	@media (min-width: 525px) {
		height: 400px;
		min-height: 400px;
	}
	height: 500px;
	min-height: 500px;
	${tw`flex flex-col items-center justify-center px-8 lg:px-12 py-12 md:py-20 w-full md:w-6/12`}
`
export const WhichModelHead = tw.h2`font-display text-3xl lg:text-4xl xxl:text-5xl font-bold text-center mb-8`
export const WhichModelText = tw.p`font-display text-2xl lg:text-3xl xxl:text-4xl font-bold md:w-11/12 xxl:w-10/12 xxxl:w-9/12 mb-10 text-center`
export const WhichModelLink = styled(Link)`
	${tw`border border-sky text-sky font-display uppercase py-3 w-6/12 lg:w-4/12 text-center rounded-lg hover:text-white hover:bg-sky`}
`

export const SpecsSection = tw.section`w-full bg-gray-300`
export const SpecsContainer = tw.div`flex flex-col md:flex-row py-12 md:py-24 px-4 lg:px-8 xl:px-16`
export const SpecContainer = styled.div`
	&:not(:last-of-type) {
		${tw`mb-10 md:mb-0`}
	}

	${tw`flex flex-col w-full md:w-4/12 px-4 md:mb-0`}
`
export const SpecTitle = tw.h3`font-display text-xl lg:text-2xl font-bold mb-10`
export const SpecList = styled.ul`
	${tw`flex flex-col`}

	> li {
		${tw`flex flex-row`}
	}

	> li > svg {
		@media (min-width: 325px) and (max-width: 767px) {
			width: 20px;
			height: 20px;
		}
		${tw`flex-none`}
	}

	> li:not(:last-of-type) {
		${tw`mb-12`}
	}
`
export const ItemName = tw.p`ml-4 font-display text-base lg:text-xl`

export const RequestSection = styled.div`
	${tw`flex flex-col items-center py-8 px-2 md:px-0 md:py-20`}

	background: #66b8d0; /* Old browsers */
	background: -moz-linear-gradient(
		left,
		#1b7db3 0%,
		#2e8fb1 50%,
		#65c0d8 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		#1b7db3 0%,
		#2e8fb1 50%,
		#65c0d8 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to left, #1b7db3 0%, #2e8fb1 50%, #65c0d8 100%);
`
export const Title = styled.h3`
	${tw`font-display text-white text-4xl md:text-5xl xl:text-6xl mb-6 md:mb-12 font-bold tracking-wide`}
	text-shadow: rgba(0,0,0,0.5) 5px 7px 11px;
`

export const PoseidonSection = styled.section`
  background: url('${({ bg }) => (bg ? bg : '')}');
  ${tw`w-full bg-cover bg-no-repeat bg-center pb-12`}
`
export const PoseidonContainer = tw.div`flex flex-col pt-12`
export const PoseidonImage = tw.img`w-9/12 md:w-8/12 lg:w-6/12 mx-auto mb-12`
export const PoseidonDescription = styled.p`
	${tw`font-display text-white text-2xl md:text-3xl text-center w-10/12 md:w-8/12 mx-auto mb-8`}
	text-shadow: rgba(0,0,0,0.5) 5px 7px 11px;
`
export const Statsbg = styled.div`
	${tw`py-6 mb-8`}
	background-color: rgba(0,0,0,.3);
`
export const StatsContainer = tw.div`flex flex-col md:flex-row w-11/12 lg:w-9/12 mx-auto`
export const StatContainer = styled.div`
	&:not(:last-of-type) {
		${tw`mb-8 md:mb-0`}
	}
	${tw`flex flex-col w-full md:w-4/12 px-4`}
`
export const StatHead = tw.h3`font-display text-white font-bold text-5xl text-center`
export const StatDescription = tw.p`text-xs font-display text-white text-center`
export const LearnMore = styled.a`
	${tw`mx-auto py-3 px-4 xxl:py-3 border-2 border-white hover:bg-transparent hover:text-white text-base md:text-lg font-display text-center rounded-lg uppercase bg-white text-blue-600`}
`
export const MainDetailsContainer = tw.div`flex flex-col md:w-6/12 items-center justify-center`
export const Reminder = styled.p`
	${tw`font-display text-black mb-3 text-sm`}
	
	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`
export const CheckboxFormContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`
